import React from 'react';
import { Helmet } from 'react-helmet';
import styled, { ThemeProvider } from 'styled-components';
import { Card, Flex } from 'rebass';
import dayjs from 'dayjs';
import 'dayjs/locale/el';

import GlobalStyle from '../global-style';
import { theme } from '../theme';

import { Navbar } from './components/Navbar';
import { Footer } from './components/Footer';

import HeroBackgroundImage from './images/pos-bg@2x.jpg';
import StationsListFetch from '../components/StationListFetch';

import i18n from '../i18n';

const Header = ({ transparent }) => <Navbar  transparent={transparent} />;

const MainContainer = styled('main')`
  flex-grow: 1;
  display: flex;
`

export const Layout = ({
  pageContext,
  transparent = true,
  footer,
  station,
  children,
}) => {
  if ('el' !== i18n.language) {
    dayjs.locale('el');
    i18n.changeLanguage('el');
  }

  return (
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyle />
        <StationsListFetch />
        <Card
          p="0"
          backgroundImage={'url(' + HeroBackgroundImage + ')'}
          backgroundSize="cover"
          backgroundRepeat="no-repeat"
          backgroundPosition={['top center', 'top left']}
          css={{ minHeight: '658px' }}
        >
          <Flex flexDirection="column" css={{ minHeight: '658px' }}>
              <Header transparent={transparent}  />
              <MainContainer id="page-wrap">{children}</MainContainer>
          </Flex>

        </Card>
        <Footer render={footer} />
        <Helmet>
          <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/Swiper/4.5.0/css/swiper.min.css"
          />
          <script src="../../BrowserPrint-2.0.0.75.min.js"></script>
          <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"></meta>
        </Helmet>
      </>
    </ThemeProvider>
  );
};


