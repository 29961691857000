import React, { Component } from 'react';
import { Box, Card, Flex, Heading, Text } from 'rebass';

import { Container } from '../../../components/container';
import BoxitLogo from '../../../components/Logo';

const Copyright = ({ mobile }) => (
  <Flex
    width={1}
    alignSelf="flex-end"
    pt={['58px', '12px', '58px']}
    pb={['31px', '30px', '48px']}
    flexDirection={mobile ? 'column' : 'row'}
    alignItems={mobile ? 'center' : ''}
  >
    <Box pb={mobile ? '31px' : 0} width={['130px', '72px', '164px']}>
      <BoxitLogo />
    </Box>
    <Text
      width={1}
      color="white"
      fontSize="11px"
      lineHeight={mobile ? '3.09' : '2.0'}
      textAlign={mobile ? 'center' : 'right'}
    >
      Copyright © {new Date().getFullYear()} WEBOXIT, All rights reserved.
    </Text>
  </Flex>
);

export class Footer extends Component {
  render() {
    return (
      <footer>
        <Card
          height="110px"
          backgroundSize="cover"
          backgroundRepeat="no-repeat"
          backgroundPosition="top center"
        >
          <Container>
            <Flex alignItems="center" height="110px">
              {this.props.render && this.props.render()}
            </Flex>
          </Container>
        </Card>
      </footer>
    );
  }
}
